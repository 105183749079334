import React from "react"
import { css } from "@emotion/core"
import Layout from "@app-components/layout/"
import Header from "@app-components/ui/header/top_bottom/"
import Feature from "@app-components/ui/feature/"
import Grid from "@app-components/ui/grid/"
import DualFeature from "@app-components/ui/dual_feature/"
import Dark from "@app-components/ui/dark/"
import LaunchFeatureImage from "@page-components/products/virtual-festival/media/launch_feature/"
import HeaderImage from "@page-components/products/virtual-festival/media/header/"
import BrandingFeatureImage from "@page-components/products/virtual-festival/media/branding_feature/"
import NativeFeatureImage from "@page-components/products/virtual-festival/media/native_feature/"
import TicketFeatureImage from "@page-components/products/virtual-festival/media/ticket_feature_on_demand/"
import IntegrationsFeatureImage from "@page-components/products/virtual-festival/media/integration_feature/"
import VideoDetailsImage from "@page-components/products/virtual-festival/media/video_details/"
import MyListImage from "@page-components/products/virtual-festival/media/my_list/"
import ShieldImage from "@page-components/products/virtual-festival/media/shield/"
import HEADERS from "@data/headers/"

const VirtualFestival = () => (
  <Layout getStartedButtons={["contact-sales"]} getStartedText="Contact sales to learn more." page="products/on-demand">
    <Header
      title="CineSend On Demand"
      text="Launch a custom-branded and highly secure TVOD / SVOD streaming platform in minutes."
      noBorder
      secondaryNavigation={HEADERS.onDemand}
      image={<HeaderImage />}
    />
    <DualFeature
      featureOne={{
        title: "Transactional (TVOD)",
        text: "Grant time-limited access to individual movies, playlists, or live events.",
        image: <VideoDetailsImage />
      }}
      featureTwo={{
        title: "Subscription (SVOD)",
        text: "Offer an all-you-can-watch service where members can browse and watch anything.",
        image: <MyListImage />
      }}
    />
    <Feature
      title="Easy to launch and maintain"
      text="Launch your platform in less than a day. Once set up, management is easy and intuitive, even for non-technical staff."
      icon="send-white"
      image={<LaunchFeatureImage />}
    />
    <Feature
      reverse
      title="Totally custom branding"
      text="Customize the look and feel of your platform, host everything on your own web domain, and integrate functionality into your existing website to maintain an experience your patrons are already familiar with."
      icon="tag"
      image={<BrandingFeatureImage />}
    />
    <Feature
      title="Native TV apps"
      text="Reach your audience with custom Apple TV, Roku, and Firestick apps that match your brand. Deliver the same tailored user experience that your customers are used to on your web platform."
      icon="tv-play"
      image={<NativeFeatureImage />}
    />
    <Grid
      title="Built for developers, too"
      description="CineSend On Demand’s REST API can be used to tightly integrate existing business systems, or implement CineSend-powered secure streaming video into entirely custom applications."
      columnWidth={3}
      small
      items={[
        {
          _id: 1,
          text: `Managed DRM business logic lets you focus on your core value proposition`,
          icon: "check-white"
        },
        {
          _id: 2,
          text: `One-click access to advanced forensic and visible watermark technology`,
          icon: "check-white"
        },
        {
          _id: 3,
          text: `Scalable technology easily serves an audience of a few to many million`,
          icon: "check-white"
        }
      ]}
    />
    <Dark>
      <div className="max-width-3 mx-auto center">
        <div
          css={css`
            width: 150px;
            margin: 0 auto 30px auto;
          `}>
          <ShieldImage />
        </div>
        <h2 className="mb2">Studio-grade security</h2>
        <p className="px4 mb3">CineSend is trusted by every MPAA member studio for good reason:</p>
      </div>
      <Grid
        columnWidth={4}
        items={[
          {
            _id: 1,
            title: "Multi-DRM Enabled",
            text: `Videos are protected with Widevine, PlayReady, and FairPlay DRM technology`,
            icon: "shield-white"
          },
          {
            _id: 2,
            title: "Geoblocking",
            text: `Restrict access to content to the country, state, or even ZIP code level`,
            icon: "vpn-lock"
          },
          {
            _id: 3,
            title: "Concurrent Stream Blocking",
            text: `Prevent users from sharing tickets or login credentials with advanced protections`,
            icon: "block"
          },
          {
            _id: 4,
            title: "Forensic Watermarking",
            text: `Invisible user-specific watermarks can be enabled to trace leaks back to users`,
            icon: "screen-search-desktop"
          },
          {
            _id: 5,
            title: "Visible Watermarking",
            text: `Optionally display user-specific visible watermarks to deter piracy`,
            icon: "watermark-white"
          },
          {
            _id: 6,
            title: "Independently Audited",
            text: `Don’t just take our word for it - we’re routinely assessed by an trusted security auditor`,
            icon: "policy"
          }
        ]}
      />
    </Dark>
    <Feature
      title="Endless monetization options"
      text="Sell tickets to individual assets, subscriptions to an entire library, or anything in between. Do it from within your platform or on your own website by pairing CineSend with your preferred monetization provider. CineSend will never touch your revenue."
      icon="link"
      image={<TicketFeatureImage />}
    />
    <Feature
      reverse
      title="Powerful live event options"
      text="Offer live events that your customers can tune into (Q&As, panels, etc) by connecting your preferred broadcast solution, or use CineSend’s built-in meeting tools for even easier setup and administration."
      icon="ticket"
      image={<IntegrationsFeatureImage />}
    />
    <Grid
      title="Deeply customizable"
      description=""
      columnWidth={4}
      items={[
        {
          _id: 1,
          title: "Powerful analytics",
          text: `Track broad engagement trends, or drill down into user-specific analytics.`,
          icon: "trend"
        },
        {
          _id: 2,
          title: "Robust API",
          text: `Use our API to build a totally custom video experience in your own applications.`,
          icon: "gears"
        },
        {
          _id: 3,
          title: "Pass Types",
          text: `Configure different pass groups to limit access to only certain videos in your library.`,
          icon: "touch"
        },
        {
          _id: 4,
          title: "Video Insertions",
          text: `Insert intro or outro clips to select videos, or set global insertions across your library.`,
          icon: "film"
        },
        {
          _id: 5,
          title: "Playlist Support",
          text: `Combine multiple videos into playlists that can be accessed as one transaction.`,
          icon: "playlist"
        },
        {
          _id: 6,
          title: "Stunning Quality",
          text: `Adaptive-bitrate means that videos will never buffer and always look fantastic.`,
          icon: "hd-white"
        }
      ]}
    />
  </Layout>
)

VirtualFestival.propTypes = {}

export default VirtualFestival
