import React from "react"
import PropTypes from "prop-types"
import Fade from "@app-components/transition/fade"

const DualFeature = ({ featureOne, featureTwo }) => (
  <Fade tagName="section" className="px2 md-px3 py4" up opacity>
    <div className="max-width-5 mx-auto">
      <div className="flex flex-wrap items-start">
        <div className="center col-12 px4 sm-col-6 lg-col-6">
          <h2 className="mb2">{featureOne.title}</h2>
          <p className="px4 mb3">{featureOne.text}</p>
          {featureOne.image}
        </div>
        <div className="center col-12 px4 sm-col-6 lg-col-6">
          <h2 className="mb2">{featureTwo.title}</h2>
          <p className="px4 mb3">{featureTwo.text}</p>
          {featureTwo.image}
        </div>
      </div>
    </div>
  </Fade>
)

DualFeature.propTypes = {
  featureOne: PropTypes.object,
  featureTwo: PropTypes.object
}

export default DualFeature
