import React from "react"
import PropTypes from "prop-types"
import Fade from "@app-components/transition/fade"
import { css } from "@emotion/core"

const Dark = ({ children }) => (
  <Fade tagName="section" up opacity>
    <div
      className="py4"
      css={css`
        background: linear-gradient(145.28deg, #3e3a52 0%, #1e1c26 100%);
        color: #fff;
      `}>
      {children}
    </div>
  </Fade>
)

Dark.propTypes = {
  children: PropTypes.object
}

export default Dark
