import React from "react"
import PropTypes from "prop-types"
import { css } from "@emotion/core"
import Link from "gatsby-link"

const ButtonWrap = ({ button, children }) => (
  <div
    className="mb2 block"
    css={css`
      @media (min-width: 320px) {
        display: inline-block !important;
        margin-right: 1rem !important;
      }
    `}>
    {button.type === "button" || !button.link ? (
      children
    ) : button.link.type === "internal" ? (
      <Link className="block" to={button.link.to}>
        {children}
      </Link>
    ) : (
      <a href={button.link.to} className="block" target="_blank" rel="noopener noreferrer">
        {children}
      </a>
    )}
  </div>
)

ButtonWrap.propTypes = {
  children: PropTypes.node.isRequired,
  button: PropTypes.object.isRequired
}

export default ButtonWrap
