import React from "react"
import PropTypes from "prop-types"
import Link from "gatsby-link"
import { css } from "@emotion/core"
import Fade from "@app-components/transition/fade"
import Icon from "@app-components/utils/icon"
import Arrow from "@app-components/utils/arrow"

const Benefits = ({ items, columnWidth, title, description, small }) => (
  <Fade tagName="section" className="px2 md-px3 py4" up opacity>
    <div className="max-width-5 mx-auto">
      {title && <h2 className="center">{title}</h2>}
      {description && <p className="max-width-3 mx-auto center large-p-tag mt2">{description}</p>}
      <div className={`flex flex-wrap items-start ${title ? "mt3" : ""}`}>
        {items.map(content => (
          <div
            className={`${
              small ? "flex items-center" : ""
            } center sm-left-align sm-flex items-start col-12 sm-col-6 lg-col-${columnWidth} px2 py3 sm-py2`}
            key={content._id}>
            <Icon icon={content.icon} className="mr0 sm-mr2 inline-flex" size={small ? "32" : "48"} />
            <div className="flex-auto pt1">
              {content.title && <h3>{content.title}</h3>}
              <p
                className="mt1"
                css={css`
                  font-size: 14px;
                `}>
                {content.text}
              </p>
              {content.link && content.link.text && (
                <Link to={content.link.to} className="bold inline-flex items-center">
                  <span className="red">{content.link.text}</span>
                  <Arrow className="block ml1" color="red" />
                </Link>
              )}
            </div>
          </div>
        ))}
      </div>
    </div>
  </Fade>
)

Benefits.defaultProps = {
  title: "",
  description: "",
  small: false
}

Benefits.propTypes = {
  items: PropTypes.array.isRequired,
  columnWidth: PropTypes.number.isRequired,
  title: PropTypes.string,
  description: PropTypes.string,
  small: PropTypes.bool
}

export default Benefits
