import React from "react"
import { StaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

const MyListImage = () => (
  <StaticQuery
    query={graphql`
      query {
        file(relativePath: { regex: "/csod_my_list.png/" }) {
          childImageSharp {
            fluid(maxWidth: 700, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `}
    render={data => (
      <Img
        className="gatsby-rounded gatsby-box-shadow"
        alt="Screenshot of my list feature"
        fluid={data.file.childImageSharp.fluid}
      />
    )}
  />
)

export default MyListImage
