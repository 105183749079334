import React from "react"
import { StaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

const NativeFeatureImage = () => (
  <StaticQuery
    query={graphql`
      query {
        file(relativePath: { regex: "/csod_native.png/" }) {
          childImageSharp {
            fluid(maxWidth: 700, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `}
    render={data => <Img alt="Native TV apps" fluid={data.file.childImageSharp.fluid} />}
  />
)

export default NativeFeatureImage
