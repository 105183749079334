import React from "react"
import PropTypes from "prop-types"
import { css } from "@emotion/core"
import red from "@media/icons/arrow/red.svg"
import white from "@media/icons/arrow/white.svg"

const Arrow = ({ color, alt, className }) => {
  const srcSet = {
    white,
    red
  }
  return (
    <img
      src={srcSet[color]}
      css={css`
        width: 20px;
      `}
      className={className}
      alt={alt}
    />
  )
}

Arrow.defaultProps = {
  alt: "Learn More",
  className: ""
}

Arrow.propTypes = {
  color: PropTypes.oneOf(["white", "red"]).isRequired,
  alt: PropTypes.string,
  className: PropTypes.string
}

export default Arrow
