import React from "react"
import PropTypes from "prop-types"
import { css } from "@emotion/core"
import Plx from "react-plx"
import { ReactComponent as RadarSVG } from "@media/svg/radar.svg"
import Background from "@app-components/utils/background"
import ButtonWrap from "./button_wrap"
import Navigation from "../components/navigation"

const Header = ({ title, text, image, blue, buttons, secondaryNavigation, noBorder }) => (
  <section>
    <Background
      tagName="div"
      className="bg-cover bg-no-repeat bg-left center white"
      type={blue ? "blue-single" : "red-single"}>
      <div
        css={css`
          padding-top: 12rem;
        `}
        className="px2 md-px3 relative">
        {secondaryNavigation && Object.prototype.hasOwnProperty.call(secondaryNavigation, "title") && (
          <Navigation navigation={secondaryNavigation} />
        )}
        <Plx
          parallaxData={[
            {
              start: "self",
              end: "100vh",
              properties: [
                {
                  startValue: 0,
                  endValue: -20,
                  property: "translateY"
                }
              ]
            }
          ]}>
          <h1>{title}</h1>
          <p className="large-p-tag max-width-2 mx-auto mt1 mb3">{text}</p>
          {Array.isArray(buttons) &&
            buttons.length > 0 &&
            buttons.map(button => (
              <ButtonWrap button={button} key={button.text}>
                <button
                  tabIndex={button.type === "button" ? "" : "-1"}
                  css={css`
                    width: 155px;
                  `}
                  className={`${button.className}`}>
                  {button.text}
                </button>
              </ButtonWrap>
            ))}
        </Plx>
        <Plx
          parallaxData={[
            {
              start: "self",
              end: "100vh",
              properties: [
                {
                  startValue: 0,
                  endValue: 10,
                  property: "translateY"
                }
              ]
            }
          ]}>
          <div
            className="relative mx-auto col-12 mt4"
            css={css`
              margin-bottom: -9rem;
              max-width: 48rem;
              z-index: -1;
            `}>
            <RadarSVG
              className="xs-hide sm-block md-block lg-block absolute top-0 left-0 right-0"
              css={css`
                transform: translateY(-25%);
                z-index: -1;
                pointer-events: none;
              `}
            />
            {image}
          </div>
        </Plx>
      </div>
    </Background>

    {!noBorder ? (
      <div className="bg-white px2 md-px3">
        <div className="py5 max-width-5 mx-auto border-bottom border-gray-5" />
      </div>
    ) : (
      <div className="bg-white px2 md-px3">
        <div className="py5 max-width-5 mx-auto" />
      </div>
    )}
  </section>
)

Header.defaultProps = {
  blue: false,
  buttons: [],
  secondaryNavigation: {},
  noBorder: false
}

Header.propTypes = {
  title: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  image: PropTypes.node.isRequired,
  blue: PropTypes.bool,
  buttons: PropTypes.array,
  secondaryNavigation: PropTypes.shape({
    title: PropTypes.string,
    icon: PropTypes.string,
    links: PropTypes.array
  }),
  noBorder: PropTypes.bool
}

export default Header
